exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-apply-tsx": () => import("./../../../src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-calculate-tsx": () => import("./../../../src/pages/calculate.tsx" /* webpackChunkName: "component---src-pages-calculate-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-guides-tsx": () => import("./../../../src/pages/guides.tsx" /* webpackChunkName: "component---src-pages-guides-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-pay-tsx": () => import("./../../../src/pages/pay.tsx" /* webpackChunkName: "component---src-pages-pay-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-programs-tsx": () => import("./../../../src/pages/programs.tsx" /* webpackChunkName: "component---src-pages-programs-tsx" */),
  "component---src-pages-ramadan-calendar-tsx": () => import("./../../../src/pages/ramadanCalendar.tsx" /* webpackChunkName: "component---src-pages-ramadan-calendar-tsx" */),
  "component---src-pages-right-to-zakat-draft-tsx": () => import("./../../../src/pages/right-to-zakat-draft.tsx" /* webpackChunkName: "component---src-pages-right-to-zakat-draft-tsx" */),
  "component---src-templates-case-tsx": () => import("./../../../src/templates/case.tsx" /* webpackChunkName: "component---src-templates-case-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-partner-tsx": () => import("./../../../src/templates/partner.tsx" /* webpackChunkName: "component---src-templates-partner-tsx" */),
  "component---src-templates-program-tsx": () => import("./../../../src/templates/program.tsx" /* webpackChunkName: "component---src-templates-program-tsx" */)
}

